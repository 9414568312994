<template>
    <div class="container text-center py-4" style="max-width:720px">
        <ul class="list-unstyled text-start">
            <li>
                1. PSS Mobile Website/App ကို Downloadပြုလုပ်ခြင်း၊ Sign Up
                ပြုလုပ်ခြင်းမှာ အခမဲ့ဖြစ်သည်။
            </li>
            <li>
                2. PSS Mobile App ကိုစတင်အသုံးပြုသည်နှင့်တပြိုင်နက်
                ပြည့်စုံသျှင်မှသတ်မှတ်ထားသည့်စည်းမျဉ်းစည်းကမ်းမူဝါဒများကို
                သဘောတူလက်ခံပါသည်ဟု အဓိပ္ပါယ် သက်ရောက်ပါသည်။
            </li>
            <li>
                3. PSS Mobile App ကိုသုံးခြင်းဖြင့် ရရှိနိုင်မည့်
                အခွင့်အရေးများမှာ သတ်မှတ် တန်ဖိုးတစ်ခု ဝယ်ယူပါက ရရှိမည့် Points
                အမျိုးမျိုး၏ခံစားခွင့်များ ၊ လက်ဆောင်များနှင့် အခြား Promotion
                အစီအစဉ်များကို ပြည့်စုံသျှင်မှ အချိန်နှင့် တပြေးညီ
                အကြောင်းကြားပေးပါမည်။
            </li>
            <li>
                4. ပြည့်စုံသျှင် Mobile App/ Website အသုံးပြုရန်အတွက်
                အကောင့်တစ်ခုပြုလုပ်ရန် ( Sign Up) လုပ်ရန်လိုအပ်ပါသည်။ Sign Up
                ပြုလုပ် အကောင့်ဖန်တီးရာတွင် email သို့မဟုတ် ဖုန်းနံပါတ် ၊ အမည် |
                လိပ်စာ အချက်အလက်များကို မှန်ကန်ပြည့်စုံစွာ ဖြည့်စွက်ပေးရန် နှင့်
                စကားဝှက် Password တစ်ခု ဖန်တီးရန် လိုအပ်ပါသည်။ ဖန်တီးထားသော
                စကားဝှက်(password ) ကို လျှို့ဝှက် ထိန်းသိမ်းရန်နှင့် Sign Up
                လုပ်ထားသော အချက် အလက်များကို ထိန်းသိမ်းရန် Sign Up လုပ်သူထံတွင်
                တာဝန်ရှိပါသည်။
            </li>
            <li>
                5. Sign Up ပြုလုပ်ရာတွင် PSS Mobile App / Website
                တွင်ဖော်ပြထားသည့် အတိုင်း အချက်အလက်များ မှန်ကန်စွာ
                ဖြည့်စွက်ရမည်။ အချက်အလက်များတောင်းခံရခြင်းမှာ Sign Up လုပ်ထားသော
                အကောင့် ပိတ်ခြင်း password မေ့ခြင်း ၊ အကောင့်ပျောက်ခြင်း
                စသည့်အခါမျိူးတွင် မှန်ကန်ကြောင်း အတည်ပြုနိုင်ရန်အတွက်ဖြစ်သည်။
            </li>
            <li>
                6. Password သည် အကောင့်ပိုင်ရှင်၏ ခွင့်ပြုချက်မရပဲ
                ပေါက်ကြားခဲ့သည်ဟုယူဆခဲ့ပါကပြည့်စုံသျှင်၏သက်ဆိုင်ရာဌာနကို
                အမြန်ဆုံးအကြောင်းကြားရန် လိုအပ်ပါသည်။
            </li>
            <li>
                7. PSS Mobile App/ Website တွင် Sign Up ပြုလုပ်ပြီးသည်နှင့်
                တပြိုင်နက် ရောက်ရှိလာနိုင်သည့် Promotion ကြော်ငြာများနှင့် App/
                Website နှင့်သက်ဆိုင်ရာ ပြောင်းလဲမှု ကြေငြာချက်များကို
                လက်ခံသဘောတူခွင့်ပြုသည်ဟုအဓိပ္ပါယ် သက်ရောက်ပါသည်။
            </li>
            <li>
                8. PSS Mobile App/ Website နှင့်ပတ်သတ်သည့်
                သတင်းအချက်အလက်များအားဖယ်ရှားခြင်း၊Sign up ပြုလုပ်ထားသော
                အကောင့်များအားဖယ်ရှားခြင်း၊အကောင့်ပိတ်ခြင်း
                ၊အကောင့်ပြင်ဆင်မွန်းမံခြင်းတို့ အပေါ် ပြည့်စုံသျှင်မှ
                ကြိုတင်အကြောင်းကြားခြင်းမရှိပဲ လုပ်ပိုင်ခွင့်ရှိသည်။
            </li>
            <li>
                9. PSS Mobile App, Website အသုံးပြုသူအနေဖြင့် အသုံးပြုနေစဉ်
                အချိန်အတွင်း မှားယွင်းမှု တစ်စုံတစ်ခု ကြုံတွေ့ခဲ့ပါက
                ပြည့်စုံသျှင်၏ Customer Service (or) Hotline ဖုန်းများသို့
                ဆက်သွယ်အကြောင်းကြားနိုင်ပါသည်။ PSS Mobile App/Website
                ပြုပြင်နေစဉ် ဝင်ရောက်အသုံးပြုရန်အတွက် Website/App
                ထဲသို့ဝင်ရောက်မရခြင်း ခေတ္တရပ်တန့်ခြင်း
                ကြန့်ကြာခြင်းများပေါ်ပေါက်ပါက ကြိုတင်အကြောင်းကြားပေးရန် ကြိုးစား
                ဆောင်ရွက်သွားပါမည်။ (Only For App/ Website Downtime)
            </li>
            <li>
                10. PSS Mobile App/Website ကို အသုံးပြုသူ Customer တစ်ဦးလျှင်
                အကောင့်တစ်ခုသာဖွင့်ခွင့်ရှိသည်၊၊ အခြားအကောင့်များတွေ့ရှိပါက
                အချိန်မရွေး အကောင့်ပိတ်ခြင်းများကို ပြည့်စုံသျှင်ဘက်မှ
                ပြုလုပ်သွားပါမည်၊၊ ကိုယ်ပိုင်အကောင့်အားရင်းနှီးချစ်ခင်မှုဖြင့်
                အခြားသူများအား လွှဲပြောင်းသုံးစွဲခွင့်မရှိပါ။
            </li>
            <li>
                11. App ကို အသုံးပြုမည့် User များအတွက် Point
                ခံစားခွင့်များရှိပါသည်။ App ကို Register ပြုလုပ်ပါက Point (200
                )ကိုစတင်ခံစားခွင့်ရှိမည်ဖြစ်ပါသည်။
            </li>
            <li>
                12. PSS Mobile App /Website တွင်ပါရှိမည့် Point များမှာ -
                (Mining Points,Royalty Points နှင့် Referral Points
                )တို့ဖြစ်ပါသည်။
            </li>
            <li>
                13. PSS Mobile App /Website မှတဆင့် ရတနာပစ္စည်းတစ်ခုဝယ်ယူတိုင်း
                Royalty Point ရမှာဖြစ်ပါသည်။ Royalty Point တွင် Gold Point နှင့်
                Diamond Point ဟူ၍ ခံစားခွင့်(၂)မျိုးရှိပါသည်။ ရွှေထည်ရတနာ (999
                24K၊ 18K) ဝယ်ယူသူတိုင်း Gold Point ရရှိမှာဖြစ်ပြီး၊
                စိန်ထည်ရတနာဝယ်ယူသူတိုင်း Diamond Point ရရှိမည်ဖြစ်ပါသည်။ Gold
                Point နှင့် Diamond Point တွင် Gold Point ကို Diamond point
                နှင့်လဲလှယ်ခွင့်ရှိပြီး Diamond Point ကို Gold Point
                နှင့်လဲလှယ်ခွင့်မရှိပါ။ Royalty Point ၏သက်တမ်းမှာ (6)လ ဖြစ်သည်။
            </li>
            <li>
                14. ရတနာများကို မိမိကိုယ်တိုင် ကနဦးဝယ်ယူပြီး
                နောက်ထပ်ဝယ်ယူမည့်သူတစ်ဦးသို့ ညွှန်းဆိုပေးပါက Refferal Point
                ရရှိမည်ဖြစ်ပြီး ညွှန်းဆိုခံရသူတွင်လည်း Discount
                ခံစားခွင့်ရရှိမည်ဖြစ်ပါသည်။ Referral Point ၏သက်တမ်း မှာ(6)လ
                ဖြစ်သည်။
            </li>
            <li>
                15. PSS Mobile App တွင် အကောင့်ဖွင့်ခြင်း၊ နေ့စဉ် Log In
                ဝင်ခြင်း၊ မိမိ Facebook အကောင့်တွင်ပြန်လည် Share ပေးခြင်း၊
                Wishlist အသဲပေးခြင်း၊ Calendar, Baydin, စတဲ့ Feature
                များကိုဝင်ရောက်အသုံးပြုခြင်း၊ Blog တွင်ဝင်ရောက်ဖတ်ရှုပြီး
                Comment များရေးသားခြင်း၊ Jewel Selfie ဖြင့်
                ဓါတ်ပုံရိုက်ကူးခြင်း၊ စတဲ့ Activities များပြုလုပ်ခြင်းဖြင့်
                Mining Point များရရှိနိုင်မည် ဖြစ်ပါသည်။ Mining Point
                ၏သက်တမ်းမှာ (3)လဖြစ်သည်။ Mining Point များကို တစ်ရက်လျှင်
                အများဆုံး Point (300) အထိရယူနိုင်မည်ဖြစ်ြပီး တစ်လလျှင် Point
                (9000)၊ နှစ်လလျှင် Point (18000)၊ သုံးလလျှင် Point
                (27000)အထိစုေဆာင်းနိုင်ြပီး Point အလိုက်Promotion
                များကိုရယူနိုင်သည်။ Mining Point , Royalty Point, Referral Point
                များကို တပြိုင်တည်းလဲလှယ်သုံးစွဲခွင့်မရှိပါ။ အထက်ပါ
                ပွိုင့်များကို App တွင်ဝယ်ယူမှသာ
                အကျိုးခံစားခွင့်များရရှိနိုင်မည်ဖြစ်ပါသည်။
            </li>
            <li>
                16. Mining Point (သို့) Royalty Point
                ကိုအများဆုံးစုဆောင်းရရှိသူများကိုလည်း Leader Board
                တွင်အစဉ်အတိုင်း ကြေငြာပေးသွားမှာဖြစ်ပြီး
                အထူးဆုလက်ဆောင်များချီးမြှင့်ပေးသွားမည်ဖြစ်ပါသည်။
            </li>
            <li>
                17. PSS Mobile App/Website တွင် အကောင့်စဖွင့်သည့်နေ့မှစတင်၍ (၆)
                လအတွင်းအသုံးပြုခြင်းမရှိပါက Point
                များအလိုအလျောက်ပျက်ပြယ်သွားပါမည်၊၊အကယ်၍ ရရိှထား‌ေသာPointများကို
                သတ်မှတ်ကာလအတွင်းအသုံးပြုမည်ဆိုပါအေကာင့်၏သက်တမ်းမှာ နာက်(6)လသို့
                အလိုအေလျှာက်သက်တမ်းတိုးမည်ြဖစ်ပါသည်။
            </li>
            <li>
                18. Point များသည် Promotion အတွက်သာအသုံးပြုနိုင်ပြီး ငွေသားနှင့်
                လှဲလှယ်ခွင့်မရှိပါ။
            </li>
            <li>
                19. Point များကို ပြည့်စုံသျှင်ဆိုင်ခွဲများတွင် (Redeem)
                ပြန်လည်ပြသ၍ အသုံးပြုခွင့်ရှိပါသည်။ ထိုသို့ ပြသရန် Mobile Device
                မရှိပါက Point များ အသုံးပြု၍မရနိုင်ပါ။ PSS Mobile App/Website
                မှရရှိသောအကျိုးခံစားခွင့်များကို အခြားတစ်ဦးတစ်ယောက်သို့
                လုံးဝလွှဲပြောင်းအသုံးပြုခွင့်မရှိပါ။
            </li>
            <li>
                20. PSS Mobile App/Website ကိုအသုံးပြုသူများသည် Point
                များအသုံးပြုခြင်း နှင့် လက်ဆောင်များ ရယူခြင်းများကို
                ပြည့်စုံသျှင်ဆိုင်ခွဲတိုင်းတွင်ပြုလုပ်နိုင်ပါသည်။
            </li>
            <li>
                21. သတ်မှတ်ထားသောစည်းမျဉ်းစည်းကမ်းများမှ သဘောတူညီမှုများကို
                ဖောက်ဖျက်ခြင်း ၊လိမ်ညာရန် ရည်ရွယ်ချက်ဖြင့်အသုံးပြုနေခြင်း၊
                မမှန်မကန်သော နည်းလမ်းဖြင့် အသုံးပြုခြင်းများကို
                စစ်ဆေးတွေ့ရှိရပါက ပြည့်စုံသျှင်မှ ပေးအပ်ထားသော Point များ၊
                အကျိုးခံစားခွင့်များကို
                အပြီးအပိုင်ရပ်ဆိုင်းမည်ဖြစ်ပြီးဆက်လက်အသုံးပြုခွင့်မရှိပဲ
                အကောင့်ပိတ်သိမ်းမည်ဖြစ်ပါသည်။
            </li>
            <li>
                22. အထက်ပါ အကြောင်းအရာနှင့်ပတ်သတ်ပြီး အငြင်းပွားဖွယ် ပြဿနာ
                တစ်စုံတစ်ရာ ဖြစ်ပွားခဲ့ပါက ပြည့်စုံသျှင်ဘက်မှ
                ဆုံးဖြတ်ချက်သာလျှင် အတည်ဖြစ်ပါသည်။
            </li>
            <li>
                23. ပြည့်စုံသျှင်မှ သတ်မှတ်ထားသောစည်းမျဉ်းစည်းကမ်း များနှင့်
                မူဝါဒများကို ကြိုတင်သတိပေးခြင်းမရှိပဲ
                အချိန်မရွေးပြုပြင်ပြောင်းလဲခြင်းမွမ်းမံဖြည့်စွက်ခြင်း ၊
                ဖယ်ရှားခြင်းများအပေါ် ပြည့်စုံသျှင်မှ လိုအပ်သလို
                ပြောင်းလဲလုပ်ပိုင်ခွင့်ရှိသည်။
            </li>
        </ul>
    </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
li {
    margin-bottom: 1rem;
}
</style>
